import logo from "./assets/imgs/logo.png";
import logo2 from "./assets/imgs/logo2.png";
import hero from "./assets/imgs/hero.png";
import ZumiIcon1 from "./assets/imgs/zumi-icon-1.png";
import ZumiIcon2 from "./assets/imgs/zumi-icon-2.png";
import ZumiIcon3 from "./assets/imgs/zumi-icon-3.png";
import ZumiIcon4 from "./assets/imgs/zumi-icon-4.png";

import featureicon1 from "./assets/imgs/featureicon1.png";
import featureicon2 from "./assets/imgs/featureicon2.png";
import featureicon3 from "./assets/imgs/featureicon3.png";
import featureicon4 from "./assets/imgs/featureicon4.png";
import featureicon5 from "./assets/imgs/featureicon5.png";
import featureicon6 from "./assets/imgs/featureicon6.png";
import featureicon7 from "./assets/imgs/featureicon7.png";
import featureicon8 from "./assets/imgs/featureicon8.png";

import FeatureVideo from "./assets/imgs/Feature-Video.mp4";
import hourglass from "./assets/imgs/hourglass.png";

import betterparkingpic from "./assets/imgs/betterparkingpic.png";

import featurepic1 from "./assets/imgs/featurepic1.png";
import featurepic2 from "./assets/imgs/featurepic2.png";

import noparkingpic from "./assets/imgs/noparkingpic.png";

import Sectionpic1 from "./assets/imgs/Sectionpic1.png";

import convrtxlogo from "./assets/imgs/convrtxlogo.png";
import "./App.css";

import { Fragment, useState, useEffect, useCallback } from "react";
import { Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Disclosure } from "@headlessui/react";

const navifooter = {
	main: [
		{ name: "Home", href: "#" },
		{ name: "Features", href: "#features" },
		{ name: "Why Spot Swap", href: "#content" },
	],
	social: [
		{
			name: "Facebook",
			href: "https://www.facebook.com/Spot.Swap23?mibextid=LQQJ4d",
			icon: (props) => (
				<svg fill="currentColor" viewBox="0 0 24 24" {...props}>
					<path
						fillRule="evenodd"
						d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
						clipRule="evenodd"
					/>
				</svg>
			),
		},
		{
			name: "Twitter",
			href: "https://twitter.com/Spot__Swap",
			icon: (props) => (
				<svg fill="currentColor" viewBox="0 0 24 24" {...props}>
					<path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
				</svg>
			),
		},
		{
			name: "Instagram",
			href: "https://instagram.com/spot__swap?igshid=ZWQyN2ExYTkwZQ==",
			icon: (props) => (
				<svg fill="currentColor" viewBox="0 0 24 24" {...props}>
					<path
						fillRule="evenodd"
						d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
						clipRule="evenodd"
					/>
				</svg>
			),
		},
	],
};

const faqs = [
	{
		id: 1,
		question: "Is Spot Swap compatible with Android and iOS?",
		answer: "Spot Swap is currently in the development stage. The app will be compatible with and available on both Android and iOS devices upon launch.",
	},
	{
		id: 2,
		question:
			"How do I get in touch with someone if there’s something wrong with my profile?",
		answer: "Please get in touch with one of our representatives using the feedback and support information provided below.",
	},
	{
		id: 3,
		question: "What are the requirements for signing up on Spot Swap?",
		answer: "Drivers will be required to enter their vehicle information, such as make, model, and license plate number. You can fund your account without a credit card. Those who wish to rent out a parking space must own the land or property that they wish to list. Anyone who wishes to list a space that they do not own, for example a parking spot in an apartment complex garage, must have express permission from the landlord or property owner before listing the space on Spot Swap.",
	},
	// More questions...
];

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const navigation = [
	{ name: "Home", href: "#" },
	{ name: "Features", href: "#features" },
	{ name: "Why Spot Swap", href: "#content" },
];

const discoverFeatures1 = [
	{
		id: 1,
		name: "",
		description:
			"Users (swapper) who are searching for a spot nearby will be able to see this spot on the map and the minutes away that it is, and immediately swap for the parking spot.",
		icon: featureicon1,
	},
	{
		id: 2,
		name: "",
		description:
			"The app will guide the swapper to the parking spot, and the host and swapper  will both be able to see their locations on a map within the app; when the swapper arrives, the host  will pull out of the spot and the swapper will move in and take the parking space and pay the host",
		icon: featureicon1,
	},
];

const discoverFeatures2 = [
	{
		id: 1,
		name: "",
		description:
			"Once a swapper (now the host)  is done with the parking spot, he or she can become the host and swap it to someone else.",
		icon: featureicon1,
	},
	{
		id: 2,
		name: "",
		description:
			"To avoid abuse of the app, users will only be able to exchange up to 20 parking spots per day.",
		icon: featureicon1,
	},
];

const features = [
	{
		name: "Book Directly",
		description: "Book appointments easily via the platform.",
		icon: ZumiIcon1,
	},
	{
		name: "Feedback",
		description: "Leave and read RVN reviews.",
		icon: ZumiIcon2,
	},
	{
		name: "Payment",
		description:
			"Pet parents pay for RVN services via a Stripe account with zumi.",
		icon: ZumiIcon3,
	},
	{
		name: "Shop",
		description:
			"Order pet products and zumi RVN merchandise directly from the app.",
		icon: ZumiIcon4,
	},
];

const Features2 = [
	{
		id: 1,
		name: "",
		description: "People will download the app, and create a user profile",
		icon: featureicon2,
	},
	{
		id: 2,
		name: "",
		description:
			"The Profile will include a picture, a picture of the car the user owns, and description of the make and model car the user drives",
		icon: featureicon3,
	},
	{
		id: 3,
		name: "",
		description:
			"Users will have the option to become the host and swapper and repeat the process of swapping parking space that they are no longer going to use.",
		icon: featureicon4,
	},
];

function App() {
	//Scroll Direction
	const [y, setY] = useState(document.scrollingElement.scrollHeight);
	const [scrollDirection, setScrollDirection] = useState(
		"fixed top-0 w-full z-50 transition-all duration-200 bg-transparent"
	);

	const handleNavigation = useCallback(
		(e) => {
			if (y > window.scrollY) {
				setScrollDirection(
					"fixed top-0 w-full z-50 transition-all duration-200"
				);
			} else if (10 < window.scrollY) {
				setScrollDirection(
					"fixed -top-full w-full z-50 transition-all duration-200"
				);
			}
			setY(window.scrollY);
		},
		[y]
	);

	useEffect(() => {
		window.addEventListener("scroll", handleNavigation);

		return () => {
			window.removeEventListener("scroll", handleNavigation);
		};
	}, [handleNavigation]);

	const useScrollHandler = () => {
		const [scroll, setScroll] = useState(1);

		useEffect(() => {
			const onScroll = () => {
				const scrollCheck = window.scrollY <= 50;
				setScroll(scrollCheck);
				console.log(scrollCheck);
			};

			document.addEventListener("scroll", onScroll);
			return () => {
				document.removeEventListener("scroll", onScroll);
			};
		}, [scroll, setScroll]);

		return scroll;
	};

	const scroll = useScrollHandler();

	return (
		<div className="App">
			{/*START*/}
			<div className={scrollDirection}>
				<Disclosure as="header" className="relative">
					<div
						className={
							scroll
								? "py-5 z-10 fixed w-full bg-transparent drop-shadow-none transition-all"
								: "py-5 z-50 fixed w-full bg-white drop-shadow-md transition-all"
						}
					>
						<nav
							className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
							aria-label="Global"
						>
							<div className="flex items-center flex-1">
								<div className="flex items-center justify-between w-full md:w-auto">
									<a href="#">
										<span className="sr-only">
											Workflow
										</span>
										<img
											className="h-10 w-auto xl:h-14"
											src={scroll ? logo : logo2}
											alt=""
										/>
									</a>
									<div className="-mr-2 flex items-center md:hidden">
										<Disclosure.Button className="bg-[#F40000] rounded-md p-2 inline-flex items-center justify-center text-uni-black hover:bg-[#F40000]">
											<span className="sr-only">
												Open main menu
											</span>
											<MenuIcon
												className="h-6 w-6"
												aria-hidden="true"
											/>
										</Disclosure.Button>
									</div>
								</div>
								<div className="hidden space-x-8 md:flex md:ml-10">
									{navigation.map((item) => (
										<a
											key={item.name}
											href={item.href}
											className={
												scroll
													? "text-base font-bold text-[white] hover:text-gray-900"
													: "text-base font-bold text-black hover:text-gray-900"
											}
										>
											{item.name}
										</a>
									))}
								</div>
							</div>
							<div className="hidden md:flex md:items-center md:space-x-6">
								<a
									href="https://forms.gle/s88SXjMqd1ncL9RdA"
									className="inline-flex items-center px-4 py-2 text-base font-bold rounded-md text-black bg-white hover:bg-white"
								>
									Pre Sign-up
								</a>
							</div>
						</nav>
					</div>

					<Transition
						as={Fragment}
						enter="duration-150 ease-out"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="duration-100 ease-in"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<Disclosure.Panel
							focus
							className="fixed text-center z-10 top-0 inset-x-0 transition transform origin-top md:hidden"
						>
							{({ close }) => (
								<div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
									<div className="px-5 pt-4 flex items-center justify-between">
										<div>
											<img
												className="h-8 w-auto"
												src={logo}
												alt=""
											/>
										</div>
										<div className="-mr-2 z-50">
											<Disclosure.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 ">
												<span className="sr-only">
													Close menu
												</span>
												<XIcon
													className="h-6 w-6"
													aria-hidden="true"
												/>
											</Disclosure.Button>
										</div>
									</div>
									<div className="pt-5 pb-6">
										<div className="px-2 space-y-1">
											{navigation.map((item) => (
												<a
													key={item.name}
													href={item.href}
													className="block px-3 py-2 rounded-md text-base font-bold text-gray-900 hover:bg-gray-50"
													onClick={async () => {
														close();
													}}
												>
													{item.name}
												</a>
											))}
										</div>
										<div className="mt-6 px-5">
											<a
												href="https://forms.gle/s88SXjMqd1ncL9RdA"
												className="block text-center w-full py-3 px-4 rounded-md shadow text-white bg-[#F40000] hover:bg-[#F40000] font-bold"
												onClick={async () => {
													close();
												}}
											>
												Pre Sign-up
											</a>
										</div>
									</div>
								</div>
							)}
						</Disclosure.Panel>
					</Transition>
				</Disclosure>
			</div>

			{/* HERO */}

			<main className="bg-[#F4F4F4]">
				<section
					id="home"
					className="bg-hero-background bg-center bg-cover lg:bg-no-repeat"
				>
					<div className="pt-10 min-h-[940px] sm:pt-16 lg:pt-20 lg:pb-14 lg:overflow-hidden">
						<div className="mx-auto lg:px-8 pt-24 lg:pt-0">
							<div className="lg:grid lg:grid-cols-12 lg:gap-8">
								<div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 text-center lg:col-span-6 xl:col-span-7 lg:px-0 lg:text-left lg:flex lg:items-center">
									<div className="lg:py-16 xl:py-24">
										<h1 className="mt-4 text-4xl leading-[3rem] tracking-tight font-Poppins font-semibold text-[white] sm:text-[36px]">
											Find your parking space with Spot
											Swap
										</h1>
										<p className="mt-3 text-base font-Poppins text-[white] sm:mt-5 lg:text-[18px] pr-8">
											Spot Swap will be an app for the
											short-term, immediate trading of
											parking spaces. The app will connect
											users who are leaving a parking
											space with those who are seeking a
											space.
										</p>
										<div className="mt-10 sm:flex sm:justify-center lg:justify-start">
											<div className="">
												<a
													href="https://forms.gle/s88SXjMqd1ncL9RdA"
													className="w-full flex items-center justify-center px-8 py-2 border border-transparent text-base font-Poppins font-medium rounded-md text-white bg-[#E52628] hover:bg-[#E52628] md:py-2 md:text-lg md:px-8"
												>
													Pre Sign-up
												</a>
											</div>
											<div className="mt-3 sm:mt-0 sm:ml-3">
												<a
													href="#content"
													className="w-full flex items-center justify-center px-8 py-2 border border-[#171A21] border-2 text-base font-Poppins font-medium rounded-md text-white bg-[#171A21] hover:bg-white hover:text-[#171A21] md:py-2 md:text-lg md:px-8"
												>
													Why Spot Swap
												</a>
											</div>
										</div>
									</div>
								</div>
								<div className="mt-12 -mb-16 sm:-mb-48 items-center lg:col-span-5 lg:m-0 lg:relative">
									<div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:pl-20 lg:pr-0 2xl:ml-20">
										<img
											className="lg:absolute z-40 pb-44 lg:pb-0 lg:pt-20 mx-auto lg:h-auto lg:max-w-none w-[120%] xl:w-auto right-[0rem] -left-[1rem] xl:-left-[5rem] 2xl:-left-[14rem] lg:pt-[7rem]"
											src={hero}
											alt=""
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section
					id="features"
					className="bg-featurebg bg-center bg-cover bg-no-repeat mt-[4rem] sm:mt-[12rem] lg:mt-0"
				>
					<div className="overflow-hidden py-16 lg:py-24">
						<div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
							<p className="mt-2 text-3xl font-extrabold tracking-tight text-white sm:text-[36px]">
								Discover Our Features
							</p>
						</div>
						<div className="overflow-hidden py-24 sm:py-32">
							<div className="mx-auto max-w-7xl md:px-6 lg:px-8">
								<div className="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
									<div>
										<div className="px-6 lg:px-0 lg:pr-4 lg:pt-4">
											<div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
												<p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
													Host
												</p>
												<p className="mt-6 text-lg leading-8 font-Poppins text-gray-400">
													When a host is ready to
													leave a parking spot, the
													host can put the parking
													spot up for sale in the app
												</p>
												<dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-400 lg:max-w-none">
													{discoverFeatures1.map(
														(feature) => (
															<div
																key={
																	feature.name
																}
																className="relative pl-9"
															>
																<dt className="inline font-semibold text-gray-900">
																	<img
																		src={
																			feature.icon
																		}
																		className="absolute left-0 top-1 h-5 w-7"
																		aria-hidden="true"
																	/>

																	{
																		feature.name
																	}
																</dt>{" "}
																<dd className="inline font-Poppins">
																	{
																		feature.description
																	}
																</dd>
															</div>
														)
													)}
												</dl>
											</div>
										</div>
										<div className="px-6 lg:px-0 lg:pr-4 pt-8 lg:pt-12">
											<div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
												<p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
													Swapper
												</p>
												<p className="mt-6 text-lg leading-8 font-Poppins text-gray-400">
													When a swapper needs a
													parking spot nearby, he or
													she will open the app and
													request a parking spot
												</p>
												<dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-400 lg:max-w-none">
													{discoverFeatures2.map(
														(feature) => (
															<div
																key={
																	feature.name
																}
																className="relative pl-9"
															>
																<dt className="inline font-semibold text-gray-900">
																	<img
																		src={
																			feature.icon
																		}
																		className="absolute left-0 top-1 h-5 w-7"
																		aria-hidden="true"
																	/>

																	{
																		feature.name
																	}
																</dt>{" "}
																<dd className="inline font-Poppins">
																	{
																		feature.description
																	}
																</dd>
															</div>
														)
													)}
												</dl>
											</div>
										</div>
									</div>
									<div className="sm:px-6 lg:px-0">
										<div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
											<video
												className="-mb-12 w-[80%] ml-[2rem] rounded-xl"
												controls="true"
												autoplay="autoplay"
												loop="true"
												muted
												defaultmuted
												playsinline
											>
												<source
													src={FeatureVideo}
													type="video/mp4"
												/>
												Your browser does not support
												HTML5 video.
											</video>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
							<div className="mx-auto max-w-[27rem] p-[.7rem] text-center text-white font-Poppins font-semibold rounded-xl bg-[#d9d9d926]">
								<img
									src={hourglass}
									className="inline h-5 w-auto pr-[3px]"
									alt=""
								/>
								Save 107+ hours a year searching for parking
							</div>
						</div>

						{/* <div className="relative mx-auto max-w-xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
							<div className="relative mt-12 sm:mt-16 lg:mt-24">
								<div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:items-center lg:gap-8">
									<div className="bg-white lg:rounded-l-[3rem] lg:col-start-2 min-h-[37rem]">
										<dl className="py-16 px-12 space-y-10">
											{discoverFeatures1.map((item) => (
												<div
													key={item.id}
													className="relative"
												>
													<dt>
														<div className="absolute flex h-12 w-12 items-center justify-center rounded-md drop-shadow-lg text-black">
															<img
																src={item.icon}
																className="h-12 w-12"
																aria-hidden="true"
															/>
														</div>
														<p className="ml-16 text-[18px] font-medium leading-6 text-[#021826]">
															{item.name}
														</p>
													</dt>
													<dd className="mt-2 ml-16 text-[18px] text-black">
														{item.description}
													</dd>
												</div>
											))}
										</dl>
									</div>

									<div className="relative -mx-4 mt-10 lg:col-start-1 lg:mt-0">
										<img
											className="relative mx-auto"
											width={490}
											src={featurepic1}
											alt=""
										/>
									</div>
								</div>
							</div>

							<div className="relative  mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
								<div className="relative bg-white lg:rounded-r-[3rem]">
									<dl className="py-16 px-12 space-y-10">
										{discoverFeatures2.map((item) => (
											<div
												key={item.id}
												className="relative"
											>
												<dt>
													<div className="absolute flex h-12 w-12 items-center justify-center rounded-md drop-shadow-lg text-white">
														<img
															src={item.icon}
															className="h-12 w-12"
															aria-hidden="true"
														/>
													</div>

													<p className="ml-16 text-[18px] font-medium leading-6 text-[#021826]">
														{item.name}
													</p>
												</dt>
												<dd className="mt-2 ml-16 text-[18px] text-black">
													{item.description}
												</dd>
											</div>
										))}
									</dl>
								</div>

								<div
									className="relative -mx-4 mt-10 lg:mt-0"
									aria-hidden="true"
								>
									<img
										className="relative mx-auto"
										width={490}
										src={featurepic2}
										alt=""
									/>
								</div>
							</div>
						</div> */}
					</div>
				</section>

				<section id="content" className="bg-[#383838]">
					<div className="mx-auto py-32 max-w-7xl sm:px-6 lg:px-8">
						<div className="overflow-hidden rounded-lg bg-whychoosebg bg-no-repeat bg-cover bg-center shadow">
							<div className="px-4 py-5 sm:p-6 min-h-[31.4rem]">
								<div className="relative px-6 lg:px-8">
									<div className="mx-auto max-w-4xl py-16">
										<div className="text-center">
											<h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-[36px]">
												Why Choose Spot Swap?
											</h1>
											<p className="mt-6 text-[20px] leading-8 font-Poppins text-white">
												Anyone who regularly drives a
												vehicle knows that finding a
												place to park can be tedious and
												time-consuming. In particular,
												commuters who reside in urban
												areas are at a distinct
												disadvantage. In New York City,
												people spend an average of 107
												hours per year looking for
												parking spaces (SpotAngels,
												2021).
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-8">
							<div className="mx-auto max-w-[27rem] p-[.7rem] text-center italic text-2xl text-gray-400 font-Poppins font-semibold">
								-Together we are the solution-
							</div>
						</div>
					</div>
				</section>

				<section
					id="home"
					className="bg-parkingsecbg bg-right-top bg-cover lg:bg-no-repeat"
				>
					<div className="pt-10 min-h-[940px] sm:pt-16 lg:pt-20 lg:pb-14 lg:overflow-hidden">
						<div className="relative px-6 lg:px-8">
							<div className="mx-auto max-w-4xl py-8">
								<div className="text-center">
									<h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-[36px]">
										Better parking space service? No
										problem!
									</h1>
								</div>
							</div>
						</div>

						<div className="overflow-hidden pb-24 sm:pb-32 pt-14">
							<div className="mx-auto max-w-7xl md:px-6 lg:px-8">
								<div className="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-12 lg:items-start">
									<div className="px-6 lg:px-0 lg:pr-4 lg:pt-4 col-span-7">
										<div className="mx-auto lg:mx-0">
											<dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-white lg:max-w-none">
												{Features2.map((feature) => (
													<div
														key={feature.id}
														className="relative bg-[#444444] py-6 pl-6 pr-[2rem] rounded-lg"
													>
														<dt className="">
															<div className="absolute flex items-center justify-center h-12 w-12 rounded-md drop-shadow-lg text-white">
																<img
																	src={
																		feature.icon
																	}
																	className="h-12 w-12 text-white"
																	aria-hidden="true"
																/>
															</div>
														</dt>{" "}
														<dd className="mt-2 ml-16 font-Poppins">
															{
																feature.description
															}
														</dd>
													</div>
												))}
											</dl>
										</div>
									</div>
									<div className="sm:px-6 lg:px-0 col-span-5">
										<div className="relative isolate overflow-hidden">
											<div className="mx-auto lg:pt-[3rem] xl:pt-0">
												<img
													src={betterparkingpic}
													alt="Product screenshot"
													className="rounded-xl"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* <div className="mx-auto lg:px-8">
							<div className="lg:grid lg:grid-cols-12 lg:gap-8">
								<div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 text-center lg:col-span-7 lg:px-0 lg:text-left lg:flex lg:items-center">
									<dl className="mt-10 space-y-10">
										{Features2.map((item) => (
											<div
												key={item.id}
												className="relative bg-white py-6 pl-6 pr-20 rounded-lg"
											>
												<dt>
													<div className="absolute flex items-center justify-center h-12 w-12 rounded-md drop-shadow-lg text-white">
														<img
															src={item.icon}
															className="h-12 w-12 text-white"
															aria-hidden="true"
														/>
													</div>

													<p className="ml-16 text-lg leading-6 font-medium text-gray-900">
														{item.name}
													</p>
												</dt>
												<dd className="mt-2 ml-16 text-base text-gray-500">
													{item.description}
												</dd>
											</div>
										))}
									</dl>
								</div>
								<div className="mt-12 -mb-16 sm:-mb-48 items-center lg:col-span-5 lg:m-0 lg:relative">
									<div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:pl-20 lg:pr-0 2xl:ml-20">
										<img
											className="lg:absolute z-40 pb-44 lg:pb-0 lg:pt-20 mx-auto lg:h-auto lg:max-w-none right-[0rem] -left-[7rem] xl:-left-[12rem] 2xl:-left-[20rem] lg:pt-[7rem]"
											src={noparkingpic}
											alt=""
										/>
									</div>
								</div>
							</div>
						</div> */}
					</div>
				</section>

				{/* faq */}
				<section
					id="faq"
					className="pt-[10.5rem] bg-[#383838] lg:pt-10"
				>
					<div className="">
						<div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:py-40 lg:px-8">
							<div className="mx-auto max-w-4xl">
								<h2 className="text-[36px] text-center font-bold font-clash leading-10 tracking-tight text-white">
									Frequently asked questions
								</h2>
								<ul role="list" className="space-y-3 pt-20">
									{faqs.map((item) => (
										<li
											key={item.id}
											className="overflow-hidden bg-[#444444] px-8 py-8 sm:rounded-md sm:px-6"
										>
											<h1 className="text-lg leading-6 font-Poppins font-medium text-white">
												{item.question}
											</h1>
											<h1 className="mt-2 text-base font-Poppins text-gray-400">
												{item.answer}
											</h1>
										</li>
									))}
								</ul>
							</div>
						</div>
					</div>
				</section>

				<section className="bg-[#383838]">
					<div>
						<div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
							<div className="bg-[#171A21] rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
								<div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-16 xl:px-16">
									<div className="lg:self-center">
										<h2 className="text-3xl font-Poppins font-extrabold text-white sm:text-[36px]">
											<span className="block">
												Accelerate towards cheaper,
												easier parking.
											</span>
										</h2>
										<p className="mt-4 text-lg leading-6 font-Poppins text-white">
											Sign up today to be notified as soon
											as SpotSwap launches.
										</p>
										<a
											href="https://forms.gle/s88SXjMqd1ncL9RdA"
											className="mt-8 bg-[#F40000] border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-white"
										>
											Pre Sign-up
										</a>
									</div>
								</div>
								<div className="">
									<img
										className="rounded-md object-fill object-left-top w-full min-h-full"
										src={Sectionpic1}
										alt="App screenshot"
									/>
								</div>
							</div>
						</div>
					</div>
				</section>

				<div className="bg-[#545454]">
					<div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
						<div className="md:grid md:grid-cols-2">
							<h2 className="md:float-left md:text-left mx-auto md:mx-0 max-w-md text-3xl font-Poppins font-extrabold text-white text-center lg:max-w-l lg:text-left">
								We have partnered with awesome people
							</h2>
							<div className="lg:text-right mt-8 md:mt-0">
								<a href="https://convrtx.com/" target="_blank">
									<img
										className="mx-auto md:float-right h-16"
										src={convrtxlogo}
										alt="Level"
									/>
								</a>
							</div>
						</div>
					</div>
				</div>

				{/** FOOTER */}

				<footer className="bg-[#171A21]">
					<div className="max-w-7xl mx-auto pt-10 md:pt-20 px-4 overflow-hidden sm:px-6 lg:px-8">
						<img className="mx-auto" src={logo} />
					</div>
					<div className="max-w-7xl mx-auto py-5 px-4 overflow-hidden sm:px-6 lg:px-8">
						<nav
							className="hidden -mx-5 -my-2 md:flex flex-wrap justify-center"
							aria-label="Footer"
						>
							{navifooter.main.map((item) => (
								<div key={item.name} className="px-5 py-2">
									<a
										href={item.href}
										className="font-Poppins text-base text-white hover:text-white"
									>
										{item.name}
									</a>
								</div>
							))}
						</nav>
						<div className="mt-8 flex justify-center space-x-6">
							{navifooter.social.map((item) => (
								<a
									key={item.name}
									href={item.href}
									target="_blank"
									className="text-white hover:text-white"
								>
									<span className="sr-only">{item.name}</span>
									<item.icon
										className="h-10 w-10 md:h-6 md:w-6"
										aria-hidden="true"
									/>
								</a>
							))}
						</div>
						<p className="mt-8 font-Poppins text-center text-base text-white">
							Sign up today to be notified as soon as Spot Swap
							launches.
						</p>
					</div>
				</footer>
			</main>
			{/*END*/}
		</div>
	);
}

export default App;
